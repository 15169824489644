<template>
  <div
    class="checkbox"
    v-for="(tag, index) in props.tags"
    :key="index"
  >
    <input
      :id="`tag-${tag.name}`"
      type="checkbox"
      :value="tag"
      class="input"
      :checked="checkNameExists(test, tag.name)"
      @change="changeStates(tag, checkNameExists(test, tag.name))"
    >
    <label
      class="label"
      :for="`tag-${tag.name}`"
    >{{ tag.name }}</label>
  </div>
</template>

<script setup lang="ts">
import type { ICbTag } from '~/interfaces'

const props = defineProps<{
  tags: ICbTag[]
}>()

const searchStore = useSearchStore()
const test = computed(() => searchStore.selectedTagArray)

// MEMO: v-modelだと初期データを反映できないのでこのようになっている -> 後からリファクタが必要
const checkNameExists = (objectsArray: ICbTag[], nameToCheck: string): boolean => {
  return objectsArray.some(obj => obj.name === nameToCheck)
}
// MEMO: v-modelだと初期データを反映できないのでこのようになっている -> 後からリファクタが必要
const changeStates = (tag: ICbTag, status: boolean): void => {
  if (status) {
    searchStore.removeSelectedTag(tag)
  } else {
    searchStore.addSelectedTag(tag)
  }
}
</script>

<style lang="scss" scoped>
.checkbox {
  gap: 0.25rem;
  display: flex;
  align-items: center;

  > .input {
    cursor: pointer;
    accent-color: #ed6484;
  }

  > .label {
    cursor: pointer;
    text-align: center;
    font-size: $body-s;
    font-family: $japanese;
    color: var(--secondary);
    letter-spacing: calc($body-s / 10);
  }
}
</style>
